<template>
    <base-index
        title="List Order"
        @onPageChanged="onPageChanged"
        @onLimitChanged="onLimitChanged"
        :entities="entities"
        :columns="columns"
        :config="config">

        <template #controls>
            <base-button
                @click="download"
                size="sm"
                type="neutral"><i class="fa fa-download"></i>Download</base-button>
        </template>

        <template #aboveFilter>
            <collapse>
                <collapse-item name="1" id="1" active>
                    <template v-slot:title>
                        <h5 class="mb-0">Show Summarized Data</h5>
                    </template>
                    <b-row>
                        <b-col xl="3" md="6">
                        <stats-card title="Total orders"
                                    type="gradient-orange"
                                    :sub-title="Number(today.total_items).toLocaleString()"
                                    icon="ni ni-chart-pie-35">
                        </stats-card>
                        </b-col>
                        <b-col xl="3" md="6">
                        <stats-card title="Normal"
                                    type="gradient-info"
                                    :sub-title="Number(today.total_normal).toLocaleString()"
                                    icon="fa fa-motorcycle">
                        </stats-card>
                        </b-col>
                        <b-col xl="3" md="6">
                        <stats-card title="Total Bus"
                                    type="gradient-red"
                                    :sub-title="Number(today.total_bus).toLocaleString()"
                                    icon="fa fa-bus">

                        </stats-card>
                        </b-col>
                        <b-col xl="3" md="6">
                        <stats-card title="Total Taxi"
                                    type="gradient-green"
                                    :sub-title="Number(today.total_taxi).toLocaleString()"
                                    icon="fa fa-taxi">

                        </stats-card>
                        </b-col>
                        <b-col xl="3" md="6">
                        <stats-card title="Total $"
                                    type="gradient-green"
                                    :sub-title="Number(today.total_price_usd).toLocaleString()"
                                    icon="ni ni-money-coins">

                        </stats-card>
                        </b-col>
                        <b-col xl="3" md="6">
                        <stats-card title="Total ៛"
                                    type="gradient-green"
                                    :sub-title="Number(today.total_price_riel).toLocaleString()"
                                    icon="ni ni-money-coins">

                        </stats-card>
                        </b-col>
                        <b-col xl="3" md="6">
                            <stats-card title="Total Profit $"
                                        type="gradient-green"
                                        :sub-title="Number(today.total_profit_usd).toLocaleString()"
                                        icon="ni ni-money-coins">

                            </stats-card>
                            </b-col>
                            <b-col xl="3" md="6">
                            <stats-card title="Total Profit ៛"
                                        type="gradient-green"
                                        :sub-title="Number(today.total_profit_riel).toLocaleString()"
                                        icon="ni ni-money-coins">

                            </stats-card>
                            </b-col>
                        </b-row>
                    </collapse-item>
                </collapse>
        </template>

        <template #filters>
            <b-col sm="3" v-if="$store.getters['auth/isSuper']">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Branch <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getBranches"
                                placeholder="Pick Branch"
                                v-model="filter.branch_id"
                            >
                                <el-option v-for="option in branches"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.name"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Operation <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getOrderItems"
                                placeholder="Pick Operation"
                                v-model="filter.operation_id"
                            >
                                <el-option v-for="option in operations"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="`${ option.name } (${ option.branch.name })`"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Customer <span class="text-danger">*</span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                remote
                                clearable
                                @change="getOrderItems"
                                :remote-method="getCustomers"
                                placeholder="Search Customer By Name Or Phone"
                                v-model="filter.customer_id"
                            >
                                <el-option v-for="option in customers"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.code"
                                        :key="option.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="2">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Driver <span class="text-danger">*</span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                remote
                                clearable
                                @change="getOrderItems"
                                :remote-method="getDrivers"
                                placeholder="Search Customer By Name Or Phone"
                                v-model="filter.driver_id"
                            >
                                <el-option v-for="option in drivers"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.code"
                                        :key="option.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="2">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Status <span class="text-danger">*</span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getOrderItems"
                                placeholder="Status"
                                v-model="filter.status"
                            >
                                <el-option v-for="option in statuses"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="`${ option.value }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="2">
                <label class="form-control-label"> Date</label>
                <base-input addon-left-icon="ni ni-calendar-grid-58" class="mt-0">
                    <flat-picker
                            @change="getOrderItems"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="filter.date">
                    </flat-picker>
                </base-input>
            </b-col>
        </template>

        <template #cell_image="{ entity }">
            <a href="javascript:void(0)" class="avatar avatar-lg rounded-circle">
                <img alt="Image placeholder" :src="entity.image ? entity.image : '/img/placeholder.jpg'">
            </a>
        </template>

        <template #cell_status="{ entity }">
            <badge :type="entity.status === 'pending' ? 'warning' : 'success'">{{ entity.status }}</badge>
            <badge v-if="entity.status === 'pending' && entity.has_pending_for" type="info">+ {{ entity.has_pending_for }} Days</badge>
        </template>

        <template #cell_driver="{ entity }">
            <badge :type="entity.driver_id ? 'default' : 'danger'">{{ entity.driver ? entity.driver.code : 'Not Assigned' }}</badge>
        </template>

        <template #others>
            <modal-order-item
                ref="modalOrderItem"
                @onAddOrderItem="onAddOrderItem"
                @onUpdateOrderItem="onUpdateOrderItem"
            />
        </template>
    </base-index>
</template>
<script>
import BaseIndex from '@components/Custom/BaseIndex';
import ModalOrderItem from './ModalOrderItem.vue';
import {
    Select,
    Option,
} from 'element-ui'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import store from './store';
import Collapse from '@/components/Collapse/Collapse'
import CollapseItem from '@/components/Collapse/CollapseItem'
import DashboardRequest from '@requests/dashboard_request';
export default {
    data() {
        return  {
            today: {},
            filter: {
                operation_id: '',
                customer_id: '',
                driver_id: '',
                branch_id: '',
                status: '',
                date: new Date()
            },
            columns: [
                {
                    label: 'Code',
                    key: 'code',
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Operation',
                    key: 'operation.name'
                },
                 {
                    label: 'Destination',
                    key: 'destination.name'
                },
                {
                    label: 'Customer',
                    key: 'customer.code'
                },
                {
                    label: 'Driver',
                    key: 'driver.code'
                },
                {
                    label: 'Status',
                    key: 'status'
                }
            ],
            config: {
                showAddNew: true,
                onClickAddNew: this.onClickAddNew,
                onClickEdit: this.onClickEdit,
                onDeleteEntity: this.onDeleteEntity,
            },
            statuses: [
                {
                    label: 'Pending',
                    value: 'pending'
                },
                {
                    label: 'Processing',
                    value: 'processing'
                },
                {
                    label: 'Finished',
                    value: 'finished'
                },
                {
                    label: 'Returned',
                    value: 'returned'
                }
            ],
        };
    },
    methods: {
        download() {
            store.dispatch('download', this.filter);
        },
        onUpdateOrderItem(orderItem) {
            store.dispatch('update', orderItem).then(() => {
                this.toastSuccess('Order Item Updated');
                this.$refs.modalOrderItem.hide();
                this.getOrderItems();
            });
        },
        onAddOrderItem(orderItem) {
            store.dispatch('store', orderItem).then(() => {
                this.toastSuccess('Order Item Added');
                this.$refs.modalOrderItem.hide();
                this.getOrderItems();
            });
        },
        onClickAddNew() {
            this.$refs.modalOrderItem.show()
        },
        onClickEdit(entity) {
            this.$refs.modalOrderItem.show(entity);
        },
        getCustomers(search) {
            store.dispatch('getCustomers', {
                search
            });
        },
        getDrivers(search) {
            store.dispatch('getDrivers', {
                search
            });
        },
        onPageChanged(page) {
            this.filter.page = page;
            this.getOrderItems();
        },
        onLimitChanged(limit) {
            this.filter.limit = limit;
            this.getOrderItems();
        },
        getOrderItems() {
            store.dispatch('index', this.filter);
            DashboardRequest.getData(this.filter).then(({ data }) => {
                this.today = data.other;
            });
        },
        getBranches() {
            store.dispatch('getBranches');
        },
        onDeleteEntity(entity) {
            store.dispatch('destroy', entity).then(() => {
                this.toastSuccess('Destination Deleted');
                this.getOrderItems();
            });
        },
    },
    mounted() {
        this.getOrderItems();
        store.dispatch('getOperations');
        store.dispatch('getBranches');
    },
    computed: {
        entities() {
            return store.getters['entities'];
        },
        operations() {
            return store.getters['operations'].filter(operation => {
                return this.filter.branch_id ? operation.branch_id === this.filter.branch_id : true;
            });
        },
        customers() {
            return store.getters['customers'];
        },
        drivers() {
            return store.getters['drivers'];
        },
        branches() {
            return store.getters['branches'];
        }
    },
    watch: {
        'filter.date': function(val) {
            if (val) {
                this.getOrderItems();
            }
        }
    },
    components: {
        BaseIndex,
        flatPicker,
        ModalOrderItem,
        [Select.name]: Select,
        [Option.name]: Option,
        Collapse,
        CollapseItem
    }
}
</script>