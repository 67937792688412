import BaseRequest from './base_request';

class CustomerRequest extends BaseRequest {

    settle(id, data) {
        return this.performRequest(BaseRequest.METHOD_POST, `${ id }/settle`, data);
    }

}

export default new CustomerRequest('customers');