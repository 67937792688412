import BaseRequest from './base_request';

class OrderItemRequest extends BaseRequest {

    getItemsByDriver(data) {
        return this.performRequest(BaseRequest.METHOD_GET, 'getItemsByDriver', data);
    }

    download(data) {
        return this.performDownload('download', data);
    }

    downloadInvoice(data) {
        return this.performDownload('downloadInvoice', data)
    }

    updateItemPayment(id, payload) {
        return this.performRequest(BaseRequest.METHOD_PUT, `${ id }/updateItemPayment`, payload);
    }

    updateDriverHasPaid(id, data) {
        return this.performRequest(BaseRequest.METHOD_PUT, `${ id }/updateDriverHasPaid`, data);
    }

    getPaidOptions() {
        return this.performRequest(BaseRequest.METHOD_GET, 'getPaidOptions');
    }

    toggleHasPaidToCustomer(id) {
        return this.performRequest(BaseRequest.METHOD_POST, `${ id }/toggleHasPaidToCustomer`);
    }

    downloadCustomerInvoice(customerId, filter, requestOptions) {
        return this.performDownload(`${ customerId }/downloadCustomerInvoice`, filter, requestOptions);
    }

    getCustomerSettlement(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'getCustomerSettlement', filter);
    }

}

export default new OrderItemRequest('order_items');