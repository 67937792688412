<template>
    <modal :show="showModal" size="lg" id="modal" ref="modal" @close="onModalClose">
        <template v-slot:header>
            <h6 class="modal-title" id="modalTitle">{{ title }}</h6>
        </template>
        <div>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        <b-col sm="6">
                            <fieldset class="form-group">
                                <div>
                                    <label class="form-control-label"> Operation <span class="text-danger">*</span></label>
                                    <div class="has-label">
                                        <el-select
                                            filterable
                                            placeholder="Pick Operation"
                                            v-model="orderItem.operation_id"
                                        >
                                            <el-option v-for="option in operations"
                                                    class="select-danger"
                                                    :value="option.id"
                                                    :label="option.name"
                                                    :key="`${ option.id }`">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </fieldset>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12">
                            <p class="text-primary"><strong>Customer Information</strong></p>
                            <hr class="mt-0 bg-primary">
                        </b-col>
                        <b-col sm="6">
                            <fieldset class="form-group">
                                <div>
                                    <label class="form-control-label"> Customer <span class="text-danger">*</span></label>
                                    <div class="has-label">
                                        <el-select
                                            filterable
                                            remote
                                            :remote-method="getCustomers"
                                            placeholder="Search Customer By Name Or Phone"
                                            v-model="orderItem.customer_id"
                                        >
                                            <el-option v-for="option in customers"
                                                    class="select-danger"
                                                    :value="option.id"
                                                    :label="option.code"
                                                    :key="option.id">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </fieldset>
                        </b-col>
                        <b-col sm="6">
                            <fieldset class="form-group">
                                <div>
                                    <label class="form-control-label"> Destination <span class="text-danger">*</span></label>
                                    <div class="has-label">
                                        <el-select
                                            filterable
                                            remote
                                            :remote-method="(search) => getDestinations(search)"
                                            placeholder="Search Destination"
                                            v-model="orderItem.destination_id"
                                        >
                                            <el-option v-for="option in orderItem.destinations"
                                                    class="select-danger"
                                                    :value="option.id"
                                                    :label="option.name"
                                                    :key="`${ option.id }`">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </fieldset>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                v-model="orderItem.price_usd"
                                type="number"
                                step="any"
                                name="price_usd"
                                label="Price (USD)">
                            </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                v-model="orderItem.price_riel"
                                type="number"
                                step="any"
                                name="price_riel"
                                label="Price (Riel)">
                            </base-input>
                        </b-col>
                        <b-col sm="6" v-if="orderItem.operation_id === 1">
                            <fieldset class="form-group">
                                <div>
                                    <label class="form-control-label">Pick Up Driver</label>
                                    <div class="has-label">
                                        <el-select
                                            filterable
                                            remote
                                            clearable
                                            :remote-method="(search) => getDrivers(search)"
                                            placeholder="Search Driver"
                                            v-model="orderItem.pick_up_driver_id"
                                        >
                                            <el-option v-for="option in drivers"
                                                    class="select-danger"
                                                    :value="option.id"
                                                    :label="option.code"
                                                    :key="`${ option.code }`">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </fieldset>
                        </b-col>
                        <!-- <b-col sm="6" v-if="orderItem.operation_id === 1"></b-col> -->
                        <!-- <b-col sm="6">
                            <template v-if="orderItem.status === 'finished' && orderItem.paid_to === 'aba'">
                                <label class="form-control-label"> Has Paid Delivery Fee <span class="text-danger">*</span></label>
                                <div class="has-label">
                                    <base-switch type="success" v-model="orderItem.has_paid_delivery_fee"></base-switch>
                                </div>
                            </template>
                        </b-col> -->
                        <b-col sm="6">
                            <template>
                                <label class="form-control-label"> Pay to delivery man </label>
                                <div class="has-label">
                                    <base-switch type="success" v-model="orderItem.pay_delivery_man"></base-switch>
                                </div>
                            </template>
                        </b-col>
                    </b-row>
                    <b-col sm="12" class="mt-2">
                        <dropzone-file-upload
                            @uploadSuccess="onUploadSuccess"
                            url='https://chatone.finance.vincenzocassano.com/v1/upload/images'
                            :options="options"
                            v-model="fileUpload">
                        </dropzone-file-upload>
                    </b-col>
                    <b-row class="mt-2">
                        <b-col sm="12">
                            <p class="text-primary"><strong>Driver Information</strong></p>
                            <hr class="mt-0 bg-primary">
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <fieldset class="form-group">
                                <div>
                                    <label class="form-control-label"> Driver</label>
                                    <div class="has-label">
                                        <el-select
                                            filterable
                                            remote
                                            clearable
                                            :remote-method="(search) => getDrivers(search)"
                                            placeholder="Search Driver"
                                            v-model="orderItem.driver_id"
                                        >
                                            <el-option v-for="option in drivers"
                                                    class="select-danger"
                                                    :value="option.id"
                                                    :label="option.code"
                                                    :key="`${ option.code }`">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </fieldset>
                        </b-col>
                        <b-col sm="6">
                            <fieldset class="form-group">
                                <div>
                                    <label class="form-control-label"> Delivery Category <span class="text-danger">*</span></label>
                                    <div class="has-label">
                                        <el-select
                                            filterable
                                            placeholder="Pick Delivery Category"
                                            v-model="orderItem.category_id"
                                        >
                                            <el-option v-for="option in categories"
                                                    class="select-danger"
                                                    :value="option.id"
                                                    :label="option.name"
                                                    :key="`${ option.id }`">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </fieldset>
                        </b-col>
                        
                        <b-col sm="6">
                            <fieldset class="form-group">
                                <div>
                                    <label class="form-control-label"> Status <span class="text-danger">*</span></label>
                                    <div class="has-label">
                                        <el-select
                                            filterable
                                            placeholder="Pick Status"
                                            v-model="orderItem.status"
                                        >
                                            <el-option v-for="option in statuses"
                                                    class="select-danger"
                                                    :value="option.value"
                                                    :label="option.label"
                                                    :key="`${ option.value }`">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </fieldset>
                        </b-col>
                        <b-col sm="6">
                            <fieldset class="form-group" v-if="orderItem.status === 'finished'">
                                <div>
                                    <label class="form-control-label"> Paid To <span class="text-danger">*</span></label>
                                    <div class="has-label">
                                        <el-select
                                            filterable
                                            placeholder="Pick Paid To"
                                            v-model="orderItem.paid_to"
                                        >
                                            <el-option v-for="key in Object.keys(paidOptions)"
                                                    class="select-danger"
                                                    :value="key"
                                                    :label="paidOptions[key]"
                                                    :key="key">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </fieldset>
                        </b-col>
                        <template v-if="orderItem.paid_to !== 'customer-aba' && orderItem.status === 'finished'">
                            <b-col sm="6">
                                <base-input
                                    v-model="orderItem.actual_receive_payment_usd"
                                    type="number"
                                    step="any"
                                    name="actual_received_amount_usd"
                                    label="Actual Received Amount (USD)">
                                </base-input>
                            </b-col>
                            <b-col sm="6">
                                <base-input
                                    v-model="orderItem.actual_receive_payment_riel"
                                    type="number"
                                    step="any"
                                    name="actual_received_amount_riel"
                                    label="Actual Received Amount (Riel)">
                                </base-input>
                            </b-col>
                        </template>
                        <b-col sm="6">
                            <base-input
                                v-model="orderItem.delivery_fee_usd"
                                type="number"
                                step="any"
                                name="delivery_fee_usd"
                                label="Delivery Fee (USD)">
                            </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                v-model="orderItem.delivery_fee_riel"
                                type="number"
                                step="any"
                                name="delivery_fee_riel"
                                label="Delivery Fee (Riel)">
                            </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                v-model="orderItem.driver_extra_delivery_fee_usd"
                                type="number"
                                step="any"
                                name="driver_extra_delivery_fee_usd"
                                label="Extra Delivery Fee (USD)">
                            </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                v-model="orderItem.driver_extra_delivery_fee_riel"
                                type="number"
                                step="any"
                                name="driver_extra_delivery_fee_riel"
                                label="Extra Delivery Fee (Riel)">
                            </base-input>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </div>
        <template v-slot:footer>
            <base-button type="secondary" @click="showModal = false">Close</base-button>
            <base-button type="primary" @click="onSubmit">Save</base-button>
        </template>
    </modal>
</template>
<script>
import DestinationRequest from '@requests/destination_request';
import DriverRequest from '@requests/driver_request';
import CategoryRequest from '@requests/category_request';
import CustomerRequest from '@requests/customer_request';
import OperationRequest from '@requests/operation_request';
import DropzoneFileUpload from '@components/Inputs/DropzoneFileUpload';
import { Select, Option } from 'element-ui';
import store from './store';
export default {
    data() {
        return {
            title: '',
            showModal: false,
            fileUpload: null,
            orderItem: this.default(),
            // categories: [],
            customers: [],
            operations: [],
            drivers: [],
            statuses: [
                {
                    label: 'Pending',
                    value: 'pending'
                },
                {
                    label: 'Processing',
                    value: 'processing'
                },
                {
                    label: 'Finished',
                    value: 'finished'
                },
                {
                    label: 'Returned',
                    value: 'returned'
                }
            ],
            options: {
                method: 'POST',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: {
                    Authorization: `Bearer ${ $cookies.get('accessToken') }`
                }
            }
        }
    },
    methods: {
        onSubmit() {
            if (this.orderItem.id) {
                this.$emit('onUpdateOrderItem', this.orderItem);
            } else {
                this.$emit('onAddOrderItem', this.orderItem);
            }
        },
        show(orderItem) {
            store.dispatch('getPaidOptions');
            this.getOperations();
            this.getCategories();
            if (orderItem) {
                this.title = 'Update Order Item';
                this.orderItem = JSON.parse(JSON.stringify(orderItem));
                this.orderItem = {
                    ...this.orderItem,
                    destination_id: this.orderItem.destination.id,
                    destinations: [this.orderItem.destination]
                }

                this.customers = [this.orderItem.customer];

                if (this.orderItem.driver_id) {
                    this.drivers .push(this.orderItem.driver);
                }

                if (this.orderItem.pick_up_driver_id) {
                    this.drivers .push(this.orderItem.pick_up_driver);
                }
            } else {
                this.orderItem = this.default();
                this.title = 'Add Order Item';
            }
            this.showModal = true;
        },
        hide() {
            this.showModal = false;
        },
        getDestinations(search) {
            DestinationRequest.index({
                name: search,
            }).then(({ data }) => {
                this.orderItem.destinations = data;
            });
        },
        getDrivers(search) {
            DriverRequest.index({
                name: search,
            }).then(({ data }) => {
                this.drivers = data;
            });
        },
        getOperations() {
            OperationRequest.index()
                            .then(({ data }) => {
                                this.operations = data;
                            });
        },
        getCategories() {
            store.dispatch('getCategories')
        },
        getCustomers(search) {
            CustomerRequest.index({
                            search
                        })
                        .then(({ data }) => {
                            this.customers = data;
                        });
        },
        default() {
            return {
                destination_id: null,
                driver_id: null,
                price_usd: '',
                price_riel: '',
                driver_extra_deliery_fee_usd: '',
                driver_extra_deliery_fee_riel: '',
                status: '',
                destinations: [],
                drivers: []
            };
        },
        onUploadSuccess(url) {
            this.orderItem.image = url;
        },
        onModalClose() {
            this.hide();
        }
    },
    computed: {
        paidOptions() {
            return store.getters.paidOptions;
        },
        categories() {
            return store.getters.categories.filter(item => item.operation_id === this.orderItem.operation_id);
        }
    },
    watch: {
        'orderItem.operation_id': function(val) {
            if (val) {
                this.categories = store.getters.categories.filter(item => item.operation_id === val);
            }
        }
    },
    components: {
        DropzoneFileUpload,
        [Select.name]: Select,
        [Option.name]: Option,
    }
}
</script>