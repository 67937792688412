import BaseRequest from './base_request';

class DashboardRequest extends BaseRequest {

    getData(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'getData', filter)
    }

}

export default new DashboardRequest('dashboard');