
export default {
    SET_ENTITIES(state, entities) {
        state.entities = entities;
    },
    SET_OPERATIONS(state, operations) {
        state.operations = operations;
    },
    SET_CUSTOMERS(state, customers) {
        state.customers = customers;
    },
    SET_DRIVERS(state, drivers) {
        state.drivers = drivers;
    },
    SET_BRANCHES(state, branches) {
        state.branches = branches;
    },
    SET_PAID_OPTIONS(state, options) {
        state.paid_options = options;
    },
    SET_CATEGORIES(state, data) {
        state.categories = data;
    }
}